function getHoverColors(primaryColorBg) {
  const hoverColors = {
    "rgb(0, 0, 0)": ["rgb(255, 255, 255)", "rgb(0, 0, 0)"],
    "rgb(51, 204, 204)": ["rgb(255, 255, 255)", "rgb(51, 204, 204)"],
    "rgb(255, 96, 80)": ["rgb(255, 255, 255)", "rgb(255, 96, 80)"],
  };
  return hoverColors[primaryColorBg] || [];
}

function createTimeline() {
  return gsap.timeline({ paused: true, overwrite: "auto" });
}

export function primaryCta() {
  const primaryCta = document.querySelectorAll(".primary-cta");
  if (!primaryCta) return;
  // Mouse enter
  primaryCta.forEach((cta) => {
    const btnWrapper = cta.parentNode;
    const circle = btnWrapper.querySelector(".primary-cta_circle");

    const primaryColorBg = window.getComputedStyle(btnWrapper).backgroundColor;
    const primaryColorTxt = window.getComputedStyle(btnWrapper).color;

    let tlEnter = createTimeline({ paused: true });
    let tlLeave = createTimeline({ paused: true });

    const [primaryColorBgHover, primaryColorTxtHover] =
      getHoverColors(primaryColorBg);

    cta.addEventListener("mouseenter", (e) => {
      const $this = e.currentTarget;
      let buttonCircle = $this.previousElementSibling;
      tlEnter = gsap.timeline({
        paused: true,
        overwrite: "auto",
        onStart: () => {
          gsap.to($this, {
            color: primaryColorTxtHover,
            duration: 0.5,
            ease: "expo.out",
          });
          gsap.to(btnWrapper, {
            backgroundColor: primaryColorBgHover,
            delay: 0.35,
            duration: 0.15,
          });
        },
        onComplete: () => {
          gsap.set(btnWrapper, {
            backgroundColor: primaryColorBgHover,
          });
        },
      });
      tlEnter.fromTo(
        buttonCircle,
        {
          opacity: 1,
          width: $this.getBoundingClientRect().width,
          height: $this.getBoundingClientRect().height * 2,
          y: $this.getBoundingClientRect().height * 2,
          backgroundColor: primaryColorBgHover,
          scale: 0,
          opacity: 0,
        },
        {
          opacity: 1,
          y: 0,
          scale: 1.2,
          duration: 0.5,
          ease: "expo.out",
        }
      );
      tlEnter.play();
    });

    // Mouse leave
    cta.addEventListener("mouseleave", (e) => {
      const $this = e.currentTarget;
      let buttonCircle = $this.previousElementSibling;

      tlLeave = gsap.timeline({
        paused: true,
        overwrite: "auto",
      });

      tlLeave.add(
        gsap.to($this, {
          color: "white",
          duration: 0.1,
        }),
        0
      );
      tlLeave.add(
        gsap.to(btnWrapper, {
          backgroundColor: primaryColorBg,
          duration: 0.1,
        }),
        0
      );
      tlLeave.add(
        gsap.to(buttonCircle, {
          opacity: 0,
          duration: 0.1,
        })
      );
      if (tlEnter.isActive()) {
        tlEnter.eventCallback("onComplete", () => tlLeave.play());
      } else {
        tlLeave.play();
      }
    });
  });
}
