import { balance } from "./animations/balance.js";
import { burger } from "./animations/burger.js";
import { buzz } from "./animations/buzz.js";
import { calculator } from "./ui/calculator/calculator.js";
import { calendly } from "./third-party/calendly/calendly.js";
import { curit } from "./animations/curit.js";
import { faq } from "./animations/faq.js";
import { frise } from "./ui/frise.js";
import { gridImages } from "./animations/gridImages.js";
import { gridMembers } from "./animations/gridMembers.js";
import { gob } from "./animations/gob.js";
import { intercom } from "./third-party/intercom.js";
import { isMobile } from "./libs/devices.js";
import { listItem } from "./animations/listItem.js";
import { loadScript } from "./libs/loader.js";
import { lsi } from "./animations/lsi.js";
import { lsiFor } from "./animations/lsiFor.js";
import { Marquee } from "./animations/marquee.js";
import { marketo } from "./third-party/forms/marketo.js";
import { nav } from "./ui/nav/nav.js";
import { primaryCta } from "./animations/primaryCta.js";
import { primaryCtaDom } from "./animations/primaryCtaDom.js";
import { scrubWordsAnimation } from "./animations/scrubWords.js";
import { testimonial } from "./animations/testimonial.js";
import { videoPopup } from "./ui/videoPopup/videoPopup.js";
import { youtube } from "./animations/youtube.js";
import { prices } from "./animations/prices.js";
import { support } from "./animations/support.js";
import { textImage } from "./animations/textImage.js";
import { keyNumbers } from "./animations/keyNumbers.js";
import { pushVideo } from "./animations/pushVideo.js";
import { summary } from "./ui/blog/summary.js";
import { list } from "./ui/blog/list.js";
import { checkList } from "./ui/blog/checklist.js";
import { keyFigures } from "./animations/keyFigures.js";
import { hero } from "./animations/hero.js";
import { mission } from "./animations/mission.js";
import { engagement } from "./animations/engagement.js";
import { homeHero } from "./animations/homeHero.js";
import { resources } from "./animations/resources.js";
import { sliderWebflow } from "./ui/sliderWebflow.js";
//
// import { customCursor } from "./animations/customCursor.js";
// import { ebook } from "./ui/slider/ebook.js";
// import { refresh } from "./animations/refresh.js";
import { horizontal } from "./ui/horizontal/horizontal.js";
import { switchLang } from "./ui/blog/switchLang.js";
import { inputSearch } from "./ui/input-search.js";
import { googletagmanager } from "./third-party/gtm/googletagmanager.js";
import { next } from "./ui/blog/next.js";

function handleResize() {
  window.dispatchEvent(new Event("resize"));
}

function init() {
  if (history.scrollRestoration) {
    history.scrollRestoration = "manual";
  }
  !window.location.href.includes("?") && window.scrollTo(0, 0);

  gsap.registerPlugin(ScrollTrigger);
  ScrollTrigger.config({
    ignoreMobileResize: true,
  });

  // CODE HERE

  const marquees = document.querySelectorAll(".marquee");
  if (marquees.length > 0) {
    marquees.forEach((marqueeEl) => {
      new Marquee(marqueeEl, {
        duration: 80,
        gap: parseInt(marqueeEl.getAttribute("data-gap")) || 20,
        direction: marqueeEl.getAttribute("data-direction") || "left",
        clones: isMobile() ? 2 : 3,
        stopOnHover: marqueeEl.classList.contains("marqueehover"),
      });
    });
  }

  const imgs = document.querySelectorAll("img");

  // Prevent drag and drop on all images
  imgs.forEach((img) => {
    img.addEventListener("dragstart", function (e) {
      e.preventDefault();
    });
  });
  // border();
  homeHero();
  gob();
  faq();
  calculator();
  calendly();
  lsi();
  videoPopup();
  nav();
  keyNumbers();
  keyFigures();
  pushVideo();
  prices();
  support();
  textImage();
  hero();
  inputSearch();
  // customCursor();
  mission();
  engagement();
  next();
  summary();
  list();
  checkList();
  switchLang();
  gridImages();
  handleResize();
  lsiFor();
  resources();
  testimonial();
  youtube();
  gridMembers();
  scrubWordsAnimation();
  primaryCta();
  curit();
  listItem();
  burger();
  horizontal();
  // refresh();
  // ebook();
  frise();
  buzz();
  balance();
  googletagmanager();
  sliderWebflow();
  window.setTimeout(handleResize, 1000);
}

primaryCtaDom();
(async () => {
  // add aspect ratio to images
  // ratios();

  // GSAP
  await loadScript(
    "js",
    "https://cdn.jsdelivr.net/npm/split-type@0.3.3/umd/index.min.js"
  );
  await loadScript(
    "js",
    "https://cdn.jsdelivr.net/npm/gsap@3.12.7/dist/gsap.min.js"
  );
  await loadScript(
    "js",
    "https://cdn.jsdelivr.net/npm/gsap@3.12.7/dist/ScrollTrigger.min.js"
  );

  window.setTimeout(() => {
    loadScript(
      "js",
      "https://cdn.jsdelivr.net/npm/@finsweet/attributes-cmsslider@1/cmsslider.js"
    );
    loadScript(
      "js",
      "https://cdn.jsdelivr.net/npm/@finsweet/attributes-numbercount@1/numbercount.js"
    );
    loadScript(
      "js",
      "https://cdn.jsdelivr.net/npm/@finsweet/attributes-accordion@1/accordion.js"
    );
    loadScript(
      "js",
      "https://cdn.jsdelivr.net/npm/@finsweet/attributes-toc@1/toc.js"
    );
    loadScript(
      "js",
      "https://cdn.jsdelivr.net/npm/@finsweet/attributes-richtext@1/richtext.js"
    );
  }, 100);

  init();

  window.setTimeout(async () => {
    // IF MARKETO Needed
    const elements = document.querySelectorAll('[id^="mktoForm_"]');
    elements.forEach((element) => {
      const id = element.getAttribute("id").replace("mktoForm_", "");
      marketo("540-UTV-492", id);
    });
  }, 1000);

  // INTERCOM
  intercom();
})();
