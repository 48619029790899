export function sliderWebflow() {
  if ($(".wrapper-testimonials").length > 0) {
    console.log('sliders');
    $(".wrapper-testimonials").each(function (index, slider) {
      const $slider = $(slider);
      console.log('slider');
      const $slides = $slider.find(".w-slide");
      if ($slider.find(".w-slide").length === 0) return;
      $slides.each((index, slide) => {
        console.log('slides');
        const $slide = $(slide);
        if ($slide.children().length === 0) {
          console.log('slide empty');
          $slide.remove();
          Webflow.destroy();
          setTimeout(() => {
            Webflow.ready();
          }, 0);
        }
      });
    });
  }
}
